.google-signup-box {
  position: relative;
  text-align: center;
  // margin: 30px auto 35px;
  width: 120px;
  // height: 50px;
  // overflow: hidden;
}

.my-facebook-button-class {
  border: none;
  background: #fff;
}

.google-signup-box .google-signup-iframe {
  opacity: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.google-signup-box iframe {
  width: 100% !important;
  z-index: 1;
  margin: 0px !important;
}

.google-signup-box .google-signup {
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  text-align: center;
  background: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #000;
  padding: 0 8px 0px;
  // position: absolute;

  right: 0;
  z-index: 0;
  width: auto;
}

.google-signup-box .google-signup img {
  vertical-align: middle;
  margin-right: 8px;
}

.green-clr {
  color: #008e7a !important;
}

.red-clr {
  color: #c42430 !important;
}

.bg-gray {
  background-color: #efefef !important;
}

.responsive-select {
  max-width: 100%;
  width: auto;
}

.default-btn {
  border-radius: 6px;
  padding: 20px;
  border: 0;
  outline: 0;
  border: 1px #008e7a solid;
  min-width: 213px;
  text-align: center;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;

  @media (max-width: 1800px) {
    min-width: 180px;
    font-size: 16px;
  }

  @media (max-width: 1600px) {
    min-width: 160px;
    padding: 18px;
  }

  @media (max-width: 767px) {
    min-width: 100%;
    padding: 15px;
  }
}

.green-bg {
  background-color: #008e7a;
  color: #fff;
}

.white-bg {
  background-color: #fff;
  color: #008e7a;
}

.login-signup-default {
  .form-box {
    margin-top: 20px;
    min-height: 295px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 675px) {
      min-height: auto;
    }
  }

  .makeStyles-roundInput-1 {
    padding: 8px 10px;
    margin: 3px;
    width: 60px;
    height: 54px;
    border-radius: 46% !important;
  }

  h1 {
    color: var(--primary-color, var(--primary-color, #008e7a));
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;

    @media (max-width: 1600px) {
      font-size: 31px;
      line-height: 1;
    }

    @media (max-width: 1200px) {
      font-size: 26px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  .height-450 {
    min-height: 450px;

    @media (max-width: 767px) {
      min-height: auto;
    }
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    margin-right: 10px;
    appearance: none;
    background: transparent;
    border-radius: 3px;
    border: 1px solid var(--grey-1, #757575);
    padding: 0;

    @media (max-width: 1600px) {
      width: 15px;
      height: 15px;
      margin-right: 4px;
    }

    &:checked {
      accent-color: #008e7a;
      appearance: auto;
    }
  }

  .right-box-outer {
    background: url("../../assert/image/patternFormBg.png") 0 0 no-repeat;
  }

  .left {
    min-height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #008e7a url("../../assert/image/patternBgWide.png") 0 0
      no-repeat;
    background-size: cover;
    height: 100%;

    @media (max-width: 767px) {
      min-height: 300px;
    }

    .img-box {
      background: #008e7a;
      border-radius: 50%;
      width: 330px;
      height: 330px;
      padding: 60px 80px;
      display: block;
      text-align: center;

      @media (max-width: 1800px) {
        width: 250px;
        height: 250px;
        padding: 40px 60px;
      }

      @media (max-width: 1140px) {
        width: 200px;
        height: 200px;
      }

      img {
        width: 100%;
      }
    }
  }

  .right {
    border-radius: 20px;
    background: #cecccc
      url("../../assert/image/patternFormBg-withbackground.png") 0 0 no-repeat;
    box-shadow: 0px 19px 40px 0px rgba(0, 0, 0, 0.05);
    max-width: 600px;
    margin: 40px auto 0;
    padding: 55px 55px 80px;
    width: 100%;
    @media (max-width: 1800px) {
      max-width: 590px;
      margin: auto;
    }

    @media (max-width: 1600px) {
      max-width: 560px;
    }
    @media (max-width: 992px) {
      padding: 30px 20px;
    }
    @media (max-width: 767px) {
      padding: 30px 15px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }

  .tagline {
    color: #686868;
    font-family: Mulish;
    font-size: 23px;
    font-style: italic;
    font-weight: 700;
    line-height: 22px;
    /* 95.652% */
    letter-spacing: 0.69px;

    @media (max-width: 1600px) {
      font-size: 18px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 1;
    }
  }

  .input-box {
    position: relative;
  }

  .input-box,
  .select-box {
    margin-top: 22px;

    @media (max-width: 1600px) {
      margin-top: 15px;
    }
  }

  .submit-box {
    padding-top: 24px;
    margin-top: auto;

    @media (max-width: 1600px) {
      padding-top: 4px;
      margin-top: 10px;
    }
  }

  .radio-box {
    margin-top: 20px;
    border-radius: 6px;
    border: 0;
    outline: 0;
    background: #fff;
    padding: 18px 20px;
  }

  .input-box,
  .submit-box {
    label {
      padding: 0 20px;
      position: absolute;
      left: 0;
      top: 20px;

      @media (max-width: 767px) {
        padding: 0 15px;
      }
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
      top: 4px;
      font-size: 14px;
    }
  }
  .input-box {
    .fileUploadBox {
      display: flex;
      input {
        flex-grow: 1;
      }
      input:not(:placeholder-shown) {
        background: rgba(235, 241, 240, 1) !important;
      }
      input::placeholder {
        color: transparent;
      }
    }
  }
  input,
  select {
    border-radius: 6px;
    border: 0;
    outline: 0;
    background: #fff;
    padding: 10px 12px;
    accent-color: #008e7a;

    @media (max-width: 767px) {
      padding: 12px 15px;
    }
  }
  select {
    padding: 18px 20px;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    color: var(--primary-color, #008e7a);
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    /* 100% */
    @media (max-width: 1600px) {
      width: 60px;
      height: 60px;
    }

    @media (max-width: 767px) {
      width: 50px;
      height: 50px;
    }
  }

  .verification-box-outer {
    P {
      margin-bottom: 0;
      color: var(--BLACK, #000);
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
      margin-top: 28px;

      @media (max-width: 767px) {
        font-size: 14px;
      }

      .green-clr {
        font-weight: 700;
      }
    }
  }

  .verification-box {
    display: flex;
    gap: 0px;
    justify-content: space-between;
    flex-direction: column;

    .varification_code {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .varification_text {
      p {
        margin-top: 11px;

        button {
          font-size: 12px;
          color: #ffffff;
          background-color: #008e7a;
          border: 1px solid #008e7a;
          border-radius: 7px;
          padding: 2px 17px;
          font-weight: 500;
          vertical-align: top;
        }
      }
    }
  }

  .link {
    color: var(--primary-color, var(--primary-color, #008e7a));
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-decoration: none;
    margin-top: 5px;
    display: inline-block;

    @media (max-width: 1600px) {
      font-size: 14px;
    }
  }

  input[type="submit"] {
    background: var(--primary-color, #008e7a);
    color: #fff;
  }

  .form-footer-links {
    display: inline-block;
    display: flex;
    gap: 10px;
    margin-top: 26px;

    @media (max-width: 1800px) {
      gap: 5px;
    }

    .links {
      border-radius: 34px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: #fff;
      padding: 10px 15px;
      color: #757575;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;

      @media (max-width: 1600px) {
        padding: 8px 10px;
        font-size: 12px;
      }

      @media (max-width: 767px) {
        text-align: center;
      }

      img {
        margin-right: 5px;

        @media (max-width: 1600px) {
          margin-right: 2px;
          max-width: 18px;
        }
      }
    }
  }

  .divide {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    &::before,
    &::after {
      content: "";
      clear: both;
      width: 176px;
      height: 1px;
      background: #ededed;
      display: block;
      right: 15px;
      position: relative;

      @media (max-width: 1600px) {
        width: 40%;
      }
    }

    &::after {
      left: 15px;
    }
  }

  .form-footer {
    color: var(--grey-1, var(--primary-color, #757575));
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 38px;

    @media (max-width: 1600px) {
      font-size: 14px;
    }

    a {
      color: #008e7a;
      font-weight: 700;
      display: inline-block;
      text-decoration: none;
    }
  }

  .err-msg {
    margin-top: 12px;

    p {
      color: #000;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;

      @media (max-width: 1600px) {
        font-size: 14px;
      }

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    ul {
      padding-left: 25px;

      li {
        color: #000;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        list-style-type: none;

        @media (max-width: 1600px) {
          font-size: 14px;
        }

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }

  .input-info {
    margin-top: 12px;
    margin-bottom: 0;
  }

  label {
    color: rgba(17, 17, 17, 0.5);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    transition: all 0.3s ease-in-out;
    line-height: 30px;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .select-time-box-outer {
    label {
      top: 0px;
      padding: 0;
    }

    .select-time-box {
      margin-top: 30px;
      gap: 20px;
    }

    .time-input {
      padding: 10px 20px;
      border-radius: 50px;
    }
  }

  .select-box {
    .select-menu {
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      background: #fff;
      color: var(--primary-color, var(--primary-color, #6b6666));
      font-family: Mulish;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .accordion-box {
    margin-top: 24px;
    border-radius: 6px;
    border: 0;
    outline: 0;
    background: #fff;
    padding: 18px 20px;

    @media (max-width: 1600px) {
      margin-top: 15px;
    }

    @media (max-width: 767px) {
      padding: 12px 15px;
    }

    &.Mui-expanded {
      margin-top: 24px;
      margin-bottom: 0;

      @media (max-width: 1600px) {
        margin-top: 15px;
      }
    }

    p {
      color: #111;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      padding-left: 0;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .MuiAccordionSummary-content {
      margin-bottom: 0;
      margin-top: 0;
    }

    li {
      padding: 0;
      color: #595959;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      /* 169.231% */
      &:not(:first-child) {
        margin-top: 10px;
      }

      span {
        color: #595959;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 169.231% */
      }
    }

    .MuiButtonBase-root {
      min-height: auto;
      padding: 0;

      &.Mui-expanded {
        min-height: auto;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }

    .heading {
      color: rgba(17, 17, 17, 0.5);
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;

      /* 20.8px */
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .checkbox-title {
      color: #595959;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 169.231% */
    }

    .sub-category-head {
      border-radius: 6px;
      background: #fff;
      padding: 20px;
    }
  }
}

.custritcontainer {
  padding: 60px 0px 140px;

  @media (max-width: 767px) {
    padding: 16px 8px;

    .makeStyles-margin50-9 {
      margin-top: -5px !important;
    }
  }

  .pt36 {
    padding-top: 236px;

    @media (max-width: 767px) {
      padding-top: 4px;
    }
  }
}

@media (max-width: 767px) {
  .makeStyles-roundInput-25 {
    width: 50px;
    height: 50px;
    color: #008e7a;
  }
}

.chang_pass {
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #008e7a;
  }
}

.cust_SignUpCustomer {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    background: white;
  }
}

@media (max-width: 1600px) {
  .login-signup-default {
    &.cust_SignUpCustomer {
      .right {
        max-width: auto;
      }
    }
  }
}
